.sec_sp {
    margin-bottom: calc(3rem + 5.128vw)
}

.table td {
    color: var(--text-color);
    padding: 20px 0;
}

.table th {
    color: var(--text-color);
    width: 50%;
    padding: 20px 0;
}

.t_border {
    border-color: var(--text-color) !important;
}

.progress-title {
    font-size: 16px;
    font-weight: 700;
    margin: 15px 0 20px;
    font-family: 'Raleway';
}

.progress {
    height: 5px;
    background: var(--secondary);
    border-radius: 0;
    box-shadow: none;
    margin-bottom: 30px;
    overflow: visible;
}

.progress .progress-bar {
    position: relative;
    background: var(--text-color);
    animation: animate-positive 2s;
    overflow: visible;
    opacity: 0.9;
    transition: width 2s ease-in-out;
    /* Animate the width change over 2 seconds */
}

.skill-image {
    max-width: 70px;
    height: auto;
    display: block;
    position: relative;
    top: 30px;
    z-index: 1;

}


.progress .progress-value {
    position: absolute;
    top: -30px;
    right: 8px;
    font-size: 17px;
    font-weight: bold;
    font-style: italic;
    color: var(--text-color);
}

@-webkit-keyframes animate-positive {
    0% {
        width: 0%;
    }
}

@keyframes animate-positive {
    0% {
        width: 0%;
    }
}

.section-title {
    font-size: 45px;
}

.service__title {
    padding: 8px 0;
    border-bottom: solid 2px var(--secondary-color);
}

.service-section .service-category-title {
    padding-bottom: 4px;
}


/*! CSS Used keyframes */

@-webkit-keyframes fadeInUp {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }
}

.modal {
    display: none;
    /* Keep modal hidden by default */
    position: fixed;
    /* Stay in place */
    z-index: 2;
    /* Sit on top */
    left: 50%;
    /* Center the modal in the viewport horizontally */
    top: 50%;
    /* Center the modal in the viewport vertically */
    width: 50%;
    /* Could be more or less, depending on screen size */
    max-width: 600px;
    /* Maximum width */
    height: auto;
    /* Adjust the height automatically */
    padding: 20px;
    border: #ffffff !important;
    color: #000;
    background-color: rgba(250, 250, 250, 0.2);
    /* Modal content background color */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    /* Add some shadow */
    transform: translate(-50%, -50%);
    /* Apply the translation */
    transition: all 0.5s ease-in-out;
    /* Smooth transition for opening/closing */
}

.modal.show {
    display: block;
    padding: 20px !important;
    /* Show modal */
}

.job-title {
    cursor: pointer;
    /* Changes the cursor to indicate the title is clickable */
    transition: color 0.3s ease;
    /* Smooth transition for color change */
}

.job-title:hover {
    color: #007bff;
    /* Change the color on hover */
    text-decoration: none;
    /* Underline the title on hover */
}

.hexagon-layout {
    text-align: center;
    font-size: 0;
    /* Remove space between inline-block elements */
}

.hex-row {
    display: flex;
    justify-content: center;
}

.hex {
    width: 150px;
    /* Set to your image width */
    height: 173px;
    /* Height to maintain aspect ratio of a hexagon (sqrt(3)/2 * width) */
    background-size: cover;
    background-position: center;
    clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
    display: inline-block;
    margin: 1.5% 0;
}

.hex-row .hex {
    margin-right: 5px;
    /* Increase spacing - adjust as needed */
}

.hex-row-2 .hex,
.hex-row-4 .hex {
    margin-top: -43px;
    /* Adjust based on your image height for overlap */
}

.hex-row-1 {
    margin-top: -70px;
}

.hex-row-3 {
    margin-top: -70px;
}

.hex-row-2 {
    margin-top: -10px;
}

.hex-row-3 .hex:not(:first-child):not(:last-child) {
    margin-right: 5px;
    /* Adjust for the middle row to be centered */
}






.close {
    color: #000000;
    float: right;
    font-size: 28px;
    font-weight: bold;
    z-index: 3;
}

/* .close:hover,
.close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
    z-index: 3;
} */

@media (max-width: 768px) {

    .table td {
        color: var(--text-color);
        padding: 20px 0 20px 0px;
    }

    .table th {
        width: 10%;
        padding: 20px 5px 20px 0px;
    }

    .hexagon-layout {
        margin-top: 3rem;
    }

    .ac_btn {
        margin-right: 0px;
    }

    .modal {
        display: block;
        padding: 20px !important;
        width: 90%;
        font-size: smaller;
        margin-top: 5rem;
        z-index: 2;
        background: none;
    }
    .modal-content{
        pointer-events: none;
    }

    /* .close {
        color: #000000;
        float: right;
        font-size: 28px;
        font-weight: bold;
        z-index: 1100;
        cursor: none;
    }
    .close:hover,
    .close:focus {
        color: #000;
        text-decoration: none;
        cursor: none;
        z-index: 1100;
    } */
}